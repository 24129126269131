<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
        @submit.native.prevent>
        <el-form-item label="姓名:" label-width="44px" class="w-200">
          <el-input v-model="table.where.name" placeholder="请输入姓名" clearable />
        </el-form-item>
        <el-form-item label="手机号:" label-width="62px" class="w-200">
          <el-input v-model="table.where.phone" placeholder="请输入手机号" clearable />
        </el-form-item>

        <el-form-item label="车牌号:" label-width="62px" class="w-200">
          <el-input v-model="table.where.car_number" placeholder="请输入车牌号" clearable />
        </el-form-item>

        <el-form-item label="审核状态:" label-width="80px" class="w-200" style="padding-left: 10px;">
					<el-select v-model="table.where.type" placeholder="请选择" clearable class="ele-fluid">
						<el-option label="全部" value="1" />
						<el-option label="审核中" value="2" />
						<el-option label="已驳回" value="3" />
					</el-select>
				</el-form-item>

        <el-form-item label="省:" label-width="35px" class="w-170">
          <el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder="请选择省" clearable>
            <el-option v-for="option in provArr" :label="option.name" :value="option.pid" :key="option.pid"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市:" label-width="35px" class="w-150">
          <el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid), $forceUpdate()"
            placeholder="请选择市" clearable>
            <el-option v-for="option in cityArr" :label="option.name" :value="option.cid" :key="option.cid"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="县/区:" label-width="62px" class="w-200 mr-20">
          <el-select v-model="table.where.aid" @change="$forceUpdate()" placeholder="请选择县/区" clearable>
            <el-option v-for="option in districtArr " :label="option.name" :value="option.aid"
              :key="option.aid"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
            class="ele-btn-icon">搜索</el-button>
          <el-button @click="reset_search()">重置</el-button>
         
         <!-- <download style="margin-left: 0" class="ele-btn-icon ml-20" label="导出" url="driver/driverCheckExportExcel"
            :params="exportOrderParams()" /> -->
            
            
           <download style="margin-left: 0" class="ele-btn-icon ml-20" label="导出" url="driver/driverCheckExportExcel"
            	:params="exportOrderParams()" :outtime='3000' ref="childDownload" />
            
            
        </el-form-item>
      </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row
        :stripe=true>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left" />
          <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
            show-overflow-tooltip />
          <el-table-column prop="driver_area" label="所属地区" show-overflow-tooltip min-width="220" />
          <el-table-column prop="true_name" label="司机姓名" show-overflow-tooltip />
          <el-table-column prop="sex" label="性别" show-overflow-tooltip>
            <template slot-scope="{row}">{{ row.sex == 0 ? '男' : '女' }}</template>
          </el-table-column>
          <el-table-column prop="driver_type" label="司机类型" show-overflow-tooltip>
            <template slot-scope="{row}">{{ row.driver_type == 1 ? '租车' : (row.driver_type == 2 ? '车主' : '/')
            }}</template>
          </el-table-column>
          <el-table-column prop="car_name" label="车辆类型" show-overflow-tooltip min-width="120" />
          <el-table-column prop="car_color" label="车辆颜色" show-overflow-tooltip min-width="120" />
          <el-table-column prop="phone" label="手机号" show-overflow-tooltip min-width="120" />
          <el-table-column prop="car_number" label="车牌号" show-overflow-tooltip min-width="100" />
          <el-table-column prop="true_head" label="真实头像" show-overflow-tooltip>
            <template slot-scope="{row}">
              <el-image style="width:25px;height:25px;" :src="row.true_head" fit="cover"
                :preview-src-list="[row.true_head]">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column label="提交时间" show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">{{ row.create_time * 1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column prop="status" label="审核状态" show-overflow-tooltip min-width="80">
            <template slot-scope="scope">
              <span>{{ scope.row.status == 1 ? '审核通过' : (scope.row.status == 2 ? '审核中' : '已驳回') }}</span>

            </template>
          </el-table-column>

          <el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
            <template slot-scope="{row}">
              <el-link @click="handlecheck(row)" icon="el-icon-view" type="primary" :underline="false"
                v-if="permission.includes('sys:dcheck:edit')">查看</el-link>
              <el-link @click="remove(row)" slot="reference" icon="el-icon-delete" type="danger" :underline="false"
                v-if="permission.includes('sys:dcheck:delete')">删除</el-link>
              <el-link icon="el-icon-success" @click="dropClick('driver_success', row)"
                v-if="permission.includes('sys:dcheck:driver_success') && row.status != 3"
                command="driver_success">通过</el-link>

              <!-- <el-link icon="el-icon-error" @click="dropClick('driver_error', row)" command="driver_error"
                v-if="permission.includes('sys:dcheck:driver_error') && row.status != 3">拒绝</el-link> -->
              <!-- <el-dropdown-item icon="el-icon-s-release"
										v-if="permission.includes('sys:driver:forblack')" command="blackData">加入黑名单
									</el-dropdown-item> -->
              <el-link icon="el-icon-error" @click="dropClick('driver_error', row)" command="driver_error"
                v-if="permission.includes('sys:dcheck:driver_error') && row.status != 3">拒绝</el-link>

              <el-dialog v-dialogDrag title="拒绝原因" :visible.sync="dialogVisiblePlans" width="800px"
                :destroy-on-close="true" :lock-scroll="false"  :modal-append-to-body="false">
                <el-form :model="editForm" ref="row" @submit.native.prevent :rules="editRules" style="margin: 40px 0;">
                  <el-form-item label="拒绝原因:" label-width="120px" prop="black_data">
                    <!-- <el-input v-model="editForm.black_data" placeholder="拉黑原因" style="width: 580px;" /> -->
                    <el-input v-model="editForm.id" style="display:none" />
                    <el-input v-model="editForm.refuse_desc" placeholder="拒绝原因" style="width: 580px;" />
                    <!-- {{ rejectId }} -->
                  </el-form-item>
                </el-form>

                <div slot="footer">

                  <el-button @click="{ dialogVisiblePlans = false ; editForm.refuse_desc = '' }">取消</el-button>
                  <el-button type="primary" @click="blackData(editForm)">确认</el-button>
                </div>
              </el-dialog>







            </template>
          </el-table-column>


        </template>
      </ele-data-table>
    </el-card>
    <!-- 查看司机详情 -->
    <el-dialog v-dialogDrag title="查看司机详情" :visible.sync="dialogTableVisibleCheck" width="900px" :destroy-on-close="true"
      :lock-scroll="false">
      <el-card shadow="never">
        <el-form :model="editForm" label-width="180px">
          <div style="display: flex;">
            <div style="padding: 0 30px 0 10px;border-right: 1px solid #C6C6C6;">
              <el-form-item label="审核状态：" prop="money">
                {{ checkForm.status == 1 ? '审核通过' : (checkForm.status == 2 ? '审核中' : '已驳回') }}
              </el-form-item>
              <!-- <el-form-item label="今日取消订单量：" prop="money">
								{{checkForm.today_cancel}}
							</el-form-item>
							<el-form-item label="本月累计取消订单量：" prop="invite_money">
								{{checkForm.thisMon_cancel}}
							</el-form-item>
							<el-form-item label="本月累计接单量：" prop="total_time">
								{{checkForm.month_order_total}}
							</el-form-item>
							<el-form-item label="本月总收益：" prop="money">
								{{checkForm.month_order_price}}
							</el-form-item>
							<el-form-item label="本月订单收益：" prop="invite_money">
								{{checkForm.month_order_earning}}
							</el-form-item>
							<el-form-item label="本月红包收益：" prop="total_time">
								{{checkForm.month_order_fee}}
							</el-form-item>
							<el-form-item label="本月推广收益：" prop="total_time">
								{{checkForm.month_order_promote}}
							</el-form-item> -->
              <el-form-item label="司机姓名：" prop="true_name">
                {{ checkForm.true_name }}
              </el-form-item>
              <el-form-item label="头像：">
                <el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.true_head" fit="cover"
                  :preview-src-list="[checkForm.true_head]"></el-image>
                <!--								<img :src="checkForm.true_head" width="70"   height="70" />-->
              </el-form-item>
              <!-- <el-form-item label="是否载客：" prop="phone"> 
								{{checkForm.is_carry}}
							</el-form-item> -->
              <el-form-item label="手机号：" prop="phone">
                {{ checkForm.phone }}
              </el-form-item>
              <el-form-item label="所属区域：">
                {{ checkForm.driver_area }}
              </el-form-item>
              <!-- <el-form-item label="累计接单收益：" prop="money">
								{{checkForm.money}}
							</el-form-item>
							<el-form-item label="诚信度：" prop="invite_money">
								{{checkForm.credit}}
							</el-form-item>
							<el-form-item label="累计在线时长：" prop="total_time">
								{{checkForm.total_time }}分钟
							</el-form-item> -->
              <el-form-item label="最近的登录时间：" prop="money">
                {{ checkForm.active_time * 1000 | toDateString }}
              </el-form-item>
              <el-form-item label="注册时间：" prop="invite_money">
                {{ checkForm.create_time * 1000 | toDateString }}
              </el-form-item>
              <el-form-item label="性别：" prop="total_time">
                {{ checkForm.sex == 0 ? '男' : '女' }}
              </el-form-item>
              <el-form-item label="真实姓名：" prop="total_time">
                {{ checkForm.true_name }}
              </el-form-item>
              <el-form-item label="身份证号：" prop="idcard">
                {{ checkForm.idcard }}
              </el-form-item>
              <!--<el-form-item label="支付宝账号：" prop="idcard">
								{{checkForm.ali_number}}
							</el-form-item>-->
              <el-form-item label="车牌号：" prop="car_number">
                {{ checkForm.car_number }}
              </el-form-item>
              <el-form-item label="司机类型：">
                {{ checkForm.driver_type == 1 ? '租车' : (checkForm.driver_type == 2 ? '车主' : '/') }}
              </el-form-item>
              <el-form-item label="驳回原因：">
                {{ checkForm.refuse_desc }}
              </el-form-item>
              <el-form-item label="车辆类型：">
                {{ checkForm.car_name }}
              </el-form-item>
              <el-form-item label="车辆颜色：">
                {{ checkForm.car_color }}
              </el-form-item>
            </div>
            <div>
              <el-form-item label="手持身份证照片：">
                <el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.idcard_hand" fit="cover"
                  :preview-src-list="[checkForm.idcard_hand]"></el-image>

                <!--								<img :src="checkForm.idcard_hand" width="70"   height="70" />-->
              </el-form-item>
              <el-form-item label="身份证正面照：">
                <el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.idcard_front" fit="cover"
                  :preview-src-list="[checkForm.idcard_front]"></el-image>

                <!--								<img :src="checkForm.idcard_front" width="70"   height="70" />-->
              </el-form-item>
              <el-form-item label="身份证反面照：">
                <el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.idcard_back" fit="cover"
                  :preview-src-list="[checkForm.idcard_back]"></el-image>

                <!--								<img :src="checkForm.idcard_back" width="70"   height="70" />-->
              </el-form-item>
              <el-form-item label="驾驶证正面照：">
                <el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.jiacard_front" fit="cover"
                  :preview-src-list="[checkForm.jiacard_front]"></el-image>

                <!--								<img :src="checkForm.jiacard_front" width="70"   height="70" />-->
              </el-form-item>
              <el-form-item label="行驶证正面照：">
                <el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.xingcard_front" fit="cover"
                  :preview-src-list="[checkForm.xingcard_front]"></el-image>
                <!--								<img :src="checkForm.xingcard_front" width="70"   height="70" />-->
              </el-form-item>
              <el-form-item label="车辆正面照：">
                <el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.car_front" fit="cover"
                  :preview-src-list="[checkForm.car_front]"></el-image>

                <!--								<img :src="checkForm.car_front" width="70"   height="70" />-->
              </el-form-item>
              <el-form-item label="从业资格证：">
                <el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.taxi_operation" fit="cover"
                  :preview-src-list="[checkForm.taxi_operation]"></el-image>

                <!--								<img :src="checkForm.taxi_operation" width="70"   height="70" />-->
              </el-form-item>
              <el-form-item label="道路运输证：">
                <el-image style="width:100px;height:100px;margin-right:10px" :src="checkForm.road_transport" fit="cover"
                  :preview-src-list="[checkForm.road_transport]"></el-image>

                <!--								<img :src="checkForm.taxi_operation" width="70"   height="70" />-->
              </el-form-item>
            </div>
          </div>
        </el-form>
      </el-card>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
//import download from '@/components/Download/index'
import download from '@/components/ListDownload/index'
export default {
  name: "SysUser",
  components: { download },
  data() {
    return {
      table: { url: '/driver/driver_check', where: { m_type: 1 } },  // 表格配置
      choose: [],  // 表格选中数据
      showEdit: false,  // 是否显示表单弹窗
      editForm: {},  // 表单数据
      editRules: {  // 表单验证规则
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的手机号',
            trigger: 'blur',
          }
        ],
        name: [
          { required: true, message: '请输入昵称', trigger: 'blur' }
        ],

      },
      provArr: [],
      cityArr: [],
      districtArr: [],
      checkForm: [],
      dialogTableVisibleCheck: false,
      dialogVisiblePlans: false,
      driverId: '',
      rejectId: ''
    }
  },
  created() {

    this.$http.get('/common/province_list').then(res => {
      let data = JSON.parse(res.data)
      this.provArr = data
    })

  },
  computed: {
    ...mapGetters(["permission"]),
  },

  mounted() {
  },
  methods: {
    // 导出数据参数
    exportOrderParams() {
      const query = this.table.where
      // const params = this.params
      const selectionIds = this.choose.map(item => {
        return item.id
      })

      return { ids: selectionIds, ...query }
    },
    
    reset_search(){
      this.$refs.childDownload.clearWhere();
      this.table.where={}
      this.$refs.table.reload()
    },
    
    

    dropClick(command, row) {
      //console.log('row', row)
      this.rejectId = row.id
      if (command === 'edit') {// 查看用户信息
        this.edit();
      } else if (command === 'remove') {//删除
        this.remove()
      } else if (command === 'driver_error') {//拒绝
        this.dialogVisiblePlans = true
        // this.blackData(row)
        // this.driver_error(row)
      } else if (command === 'driver_success') {//通过
        this.driver_success(row)
      }
      this.$forceUpdate()
    },
    /**
     *选择省
     **/
    handleChangeProv(e) {
      /** 获取被选省份的pid**/
      let pid = ''
      this.provArr.forEach(function (item) {
        if (item.pid == e) {
          pid = item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list', { pid: pid }).then(res => {
        let data = JSON.parse(res.data)
        this.cityArr = data
        /** 选择省份清空市县**/
        this.table.where.cid = ''
        this.table.where.aid = ''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e) {
      if (e) {
        /** 获取被选市的cid**/
        let cid = ''
        this.cityArr.forEach(function (item) {
          if (item.cid == e) {
            cid = item.cid
          }
        })
        /** 根据被选市的cid获取市下面的县**/
        this.$http.post('/common/area_list', { cid: cid }).then(res => {
          let data = JSON.parse(res.data)
          this.districtArr = data
          /** 选择省份清空县**/
          this.table.where.aid = ''
        })
      } else {
        this.table.where.pid = ''
        this.table.where.aid = ''
      }
    },
    /**
     *查看用户信息
     **/
    handlecheck(row) {
      this.dialogTableVisibleCheck = true
      this.checkForm = row;
    },
    /**
     * 显示编辑
     */
    edit(row) {
      //  	alert(123);
      this.$http.get('/driver/info?id=' + row.id).then(res => {
        if (res.data.code === 0) {
          this.editForm = res.data.data;
          //        alert(123);
          this.showEdit = true;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },
    /**
     * 保存编辑
     */
    save() {
      this.editForm.pid = this.pid
      this.editForm.cid = this.cid
      this.editForm.aid = this.aid
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({ lock: true });
          this.$http.post('/driver/edit', this.editForm).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({ type: 'success', message: res.data.msg });
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /**
     * 刪除(批量刪除)
     */
    remove(row) {
      if (!row) {  // 批量删除
        if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
        let ids = this.choose.map(d => d.id);
        this.$confirm('确定要删除选中的司机吗?', '提示', { type: 'warning' }).then(() => {
          const loading = this.$loading({ lock: true });
          this.$http.post('/driver/delete', { id: ids }).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({ type: 'success', message: res.data.msg });
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        }).catch(() => 0);
      } else {  // 单个删除
        this.$confirm('确定要删除选中的司机吗?', '提示', { type: 'warning' }).then(() => {
          const loading = this.$loading({ lock: true });
          this.$http.post('/driver/delete', { id: row.id }).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({ type: 'success', message: res.data.msg });
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        })
      }
    },

    driver_success(row) {
      this.$confirm('确认通过司机认证审核？', '提示', { type: 'warning' }).then(() => {
        const loading = this.$loading({ lock: true });
        this.$http.post('/driver/driver_success', { id: row.id }).then(res => {
          loading.close();
          if (res.data.code === 0) {
            this.$message({ type: 'success', message: res.data.msg });
            this.$refs.table.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(e => {
          loading.close();
          this.$message.error(e.message);
        });
      })


    },
    // driver_error(row) {

    // this.$confirm('确认驳回司机认证审核？', '提示', { type: 'warning' }).then(() => {
    //   const loading = this.$loading({ lock: true });
    //   this.$http.post('/driver/driver_error', { id: row.id }).then(res => {
    //     loading.close();
    //     if (res.data.code === 0) {
    //       this.$message({ type: 'success', message: res.data.msg });
    //       this.$refs.table.reload();
    //     } else {
    //       this.$message.error(res.data.msg);
    //     }
    //   }).catch(e => {
    //     loading.close();
    //     this.$message.error(e.message);
    //   });
    // })



    // },
    blackData(e) {
      //console.log(e)

      const loading = this.$loading({ lock: true });
      this.$http.post('/driver/driver_error', { id: this.rejectId, refuse_desc: e.refuse_desc }).then(res => {
        loading.close();
        if (res.data.code === 0) {
          this.$message({ type: 'success', message: res.data.msg });
          this.dialogVisiblePlans = false;
          this.editForm.refuse_desc = ''
          this.$refs.table.reload();
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        loading.close();
        this.$message.error(e.message);
      });

      // const loading = this.$loading({
      //   lock: true
      // });
      // this.$http.post('/driver/driver_error', {
      //   refuse_desc: e.refuse_descd,
      //   id: e.id
      // }).then(res => {
      //   loading.close();
      //   if (res.data.code === 0) {
      //     this.$message({
      //       type: 'success',
      //       message: res.data.msg
      //     });
      //     this.dialogVisiblePlans = false;
      //     this.$refs.table.reload();
      //   } else {
      //     this.$message.error(res.data.msg);
      //   }
      // }).catch(e => {
      //   loading.close();
      //   this.$message.error(e.message);
      // });
    },

    /* 导出数据Excel */
    exportExcel() {
      let info = JSON.parse(JSON.stringify(this.table.where));
      //console.log('user data export')
      this.$http
        .get("/driver/exportExcelForCheck", info)
        .then((res) => {
          let data = res.data;
          if (data.code == 0) {
            // 下载文件
            window.location.href = data.data;
            this.$message({
              type: "success",
              message: "导出成功",
            });
          } else {
            this.$message.error("导出失败");
          }
        })
    },

  }
}
</script>

<style scoped>
.ele-block .el-upload-dragger {
  width: 100%;
}

/deep/.el-dialog {
  margin-top: 0px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}
.ele-fluid{
  width: 100px;
}
</style>